.recent-content {
    font-size: 60px;
    font-weight: 700;

  }
  /* RecentWork.css */
.image-container {
  position: relative;
  width: 100%;
}

.pioneer-image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.image-container:hover .overlay {
  height: 100%;
}

.text {
  color: white;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 15px;
  text-align: justify;
}

.text h3 {
  margin: 10px 0;
}

.text p {
  margin: 5px 0;
}


  
  @media only screen and (max-width: 768px) {
    .recent-content {
      font-size: 40px !important; /* Adjust font size for smaller screens */
    }
    .latest-title {
        padding-top: 40px;
        font-size: 70px;
        text-align: left;
      }
      .Service-title h2 {
        padding-top: 20px;
        font-size: 40px;
        font-weight: 700;
       
      }
  }
  
  @media only screen and (max-width: 576px) {
    .recent-content {
      font-size: 30px !important; /* Adjust font size for even smaller screens */
    }
    .latest-title {
        padding-top: 40px;
        font-size: 40px;    
        text-align: left;

      }
      .Service-title h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 35px;
       
      }
  }
  