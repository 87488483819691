
.header {
    position: relative;
}

.service-banner {
    position: relative;
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Add space between items */
    width: 100%;
    height: 450px; /* Adjust the height as needed */
    overflow: hidden; /* Hide overflow content */
    background-image: url('../assets/images/serviceBanner.png'); /* Set the background image */
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the background image */
}

.service-text {
    padding: 20px;
    color: white; /* Text color */
    max-width: 50%; /* Limit text width */
}

.service-text h1 {
    padding: 20px;
    color: rgba(255, 255, 255, 0.9);
    max-width: 50%;
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 50px;
}

.service-banner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity and color as needed */
}

