[type=radio] {
  display: none;
}
.ant-tabs-tabpane {
  text-align: justify;
}

#slider {
  height: 28vw;
  width: 25vw;
  margin: 0 auto;
  left: -10%;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
}

#slider label {
  margin: auto;
  background-color: aliceblue;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  left: 0; right: 0;
  cursor: pointer;
  transition: transform 0.4s ease;
}

#s1:checked ~ #slide3, #s2:checked ~ #slide1,
#s3:checked ~ #slide2 {
  box-shadow: 0 6px 10px 0 rgba(0,0,0,.3), 0 2px 2px 0 rgba(0,0,0,.2);
  transform: translate3d(-20%,0,-100px);
}

#s1:checked ~ #slide1, #s2:checked ~ #slide2,
#s3:checked ~ #slide3 {
  box-shadow: 0 13px 25px 0 rgba(0,0,0,.3), 0 11px 7px 0 rgba(0,0,0,.19);
  transform: translate3d(0,0,0);
}

#s1:checked ~ #slide2, #s2:checked ~ #slide3,
#s3:checked ~ #slide1 {
  box-shadow: 0 6px 10px 0 rgba(0,0,0,.3), 0 2px 2px 0 rgba(0,0,0,.2);
  transform: translate3d(20%,0,-100px);
}
.card-btn {
    color: #203556;
    background-color: white;
    font-size: 25px;
    border: none;
}
.for-padding {
 padding-left: 50px;
}
/* Tab.css */
.ant-tabs-tab-active {
    font-size: 22px;
    color: red!important; 


  }
  .ant-tabs-tab {
    font-size: 22px !important;
    color: #c8ccd3 !important; 
    font-weight: bold; 
  }
  .ant-tabs-content {
    color: #8288a0; 
    font-size: 17px !important;
     
  }
  .additional-content h5 {
    color: #203556 ;
    font-size: 17px;
    font-weight: 600;
    padding: 10px 0px 10px 0px;

  }
  
  /* Define animation keyframes */
@keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  /* ImageCarousel.css */
.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
}
.recent-btn:hover {
  background-color: white !important;
}
.recent-btn:hover .recent-btn-icon {
  color: #203556 !important;
}
/* Owl Carousel responsive styles */
.owl-carousel .owl-stage-outer {
  overflow: hidden;
}

.owl-carousel .owl-item {
  display: flex;
  justify-content: center;
}



.owl-carousel .owl-prev {
  left: 10px;
}


.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  transition: transform 0.3s ease;
  padding: 10px;
}

.image-container:hover img {
  transform: scale(1.1);
}
/* CardCarosel.css */



@media screen and (max-width: 768px) {
  .owl-carousel .owl-nav {
    display: none;
  }
}

  
  /* Apply animation to the additional content */
  .additional-content {
    animation: slideIn 0.5s ease-in-out;
  }
  .assurance-columns {
    text-align: center;
  }
  
  .assurance-columns img {
    max-width: 30%;
    height: auto;
  }
  
  .assurance-columns h3 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
  }
  
  .assurance-columns p {
    margin-top: 10px;
    font-size: 15px;
    color: #6a708c;
  }
  
  
  @media only screen and (max-width: 768px) {
    .column {
      padding: 15px;
    }
  }
  .assurance-container 
  {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  
@media only screen and (max-width: 576px) {
    #slider {
        height: 50vw;
        width: 50vw;
     
      }
      
      #slider label {
        width: 100%;
        height: 100%;
      
      }
  }
 
  .container {
    position: relative;
  }
  
  /* Hide the images by default */
  .mySlides {
    display: none;
  }
  
  /* Add a pointer when hovering over the thumbnail images */
  .cursor {
    cursor: pointer;
  }
  
  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  /* Container for image text */
  .caption-container {
    text-align: center;
    background-color: #222;
    padding: 2px 16px;
    color: white;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Six columns side by side */
  .column {
    float: left;
    width: 16.66%;
  }
  
  /* Add a transparency effect for thumnbail images */
  .demo {
    opacity: 0.6;
  }
  
  .active,
  .demo:hover {
    opacity: 1;
  }
  .slider-button {
    background-color: #203556;
    border: none;
    padding: 20px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for color change */
  }
  
  .slider-button:hover {
    background-color: #fff; /* Change background color to white on hover */
  }
  
  .slider-button:hover svg {
    color: #000; /* Change icon color to black on hover */
  }
  
