.custom-footer {
  background: linear-gradient(to right, #0a0608, #2a617e);
  color: white;
  padding: 40px 0;
  text-align: left;
}

.footer-logo {
  width: 150px;
  margin-bottom: 20px;
}

.footer-address, .useful-links, .opening-hours {
  margin-bottom: 20px;
}

.footer-column h3 {
  color: #c1dcdc;
  margin-bottom: 15px;
}

.footer-address p,
.opening-hours p,
.useful-links ul li h6 {
  margin: 0 0 10px;
  line-height: 1.5;
}

.footer-address a,
.custom-links {
  color: #c1dcdc;
  text-decoration: none;
}

.footer-address a:hover,
.custom-links:hover {
  text-decoration: underline;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li h6 {
  margin: 0;
}

.footer-column ul li a {
  color: white;
}

.footer-column ul li a:hover {
  color: #be1e2d;
}

@media (max-width: 768px) {
  .footer-column {
    text-align: center;
  }

  .footer-address, .useful-links, .opening-hours {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .footer-logo {
    width: 120px;
  }

  .footer-address p, .opening-hours p, .useful-links ul li h6 {
    font-size: 14px;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.copyright {
  text-align: center;
  background-color: #001529;
  padding: 20px 0;
  color: #fff;
  margin-top: 20px;
}
.sub-address {
  font-size: 17px;
  
}