.news-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 100%;
    height: 450px;
    overflow: hidden; 
    background-image: url('../assets/images/NwesBanner.jpg'); 
    background-size: cover; 
    background-position: center; 
}

.news-text {
    padding: 20px;
    color: white; 
    max-width: 50%; 
}

.news-text h1 {
    padding: 20px;
    color: rgba(255, 255, 255, 0.9);
    max-width: 50%;
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 50px;
    top: 60px;
}

.news-banner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
}
.news-content h2 {
    padding: 20px 0px 20px 0px;
    font-size: 34px;
    font-weight: 700;
    color: transparent; 
    background-image: linear-gradient(to right, #840808 ,#f77b7a); 
    -webkit-background-clip: text; 
    background-clip: text; 
}
.certificate img {
    width: 100%;
    height: 600px;
}