.contact-banner {
    position: relative;
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Add space between items */
    width: 100%;
    height: 450px; /* Adjust the height as needed */
    overflow: hidden; /* Hide overflow content */
    background-image: url('../assets/images/contactUs.jpg'); /* Set the background image */
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the background image */
}
.contact-para {
    font-size: 18px;
    font-weight:500;
    color: #203556;
}
/* Contact Info Styles */
.contact-info {
    margin-top: 20px;
  }
  
  /* Contact Item Styles */
  .contact-item {
    margin-bottom: 20px;
  }
  
  .contact-item span {
    font-weight: bold;
    margin-right: 5px;
    color: #203556;
  }
  
  .contact-item p {
    color: #949db4;
    font-size: 16px;
  }
  /* Social Icons Styles */
.contact-social-icons {
    margin-top: 10px;
  }
  
  .contact-social-icons a {
    display: inline-block;
    margin-right: 10px;
  
  }
  
  .contact-social-icons a i {
    font-size: 24px;
   
  }
  .contact-form {
    padding: 60px;
    border-radius: 50px;
    box-shadow: 0 0 10px #203556;
    border: 1px solid #b4baca;
}

.contact-form h2 {
    margin-bottom: 20px;
    font-size: 34px;
    color: #203556;
}

.ant-form-item-label {
    font-weight: bold;
    color: #adb3c5;
}

.ant-form-item-explain-error {
    color: #203556 !important;
}

.ant-input {
    border-radius: 30px;
}

.ant-input:focus,
.ant-input-focused {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.ant-input::placeholder {
    color: #999;
}

.ant-btn {
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: bold;
}

.ant-btn-primary {
    border-radius: 20px;
    margin-right: 10px;
    background-color: #203556;
    border-color: white;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background-color: white;
    border-color: #203556;
}

.ant-btn-primary:active,
.ant-btn-primary.active {
    background-color: gray;
    border-color: #096dd9;
}

.ant-btn-default {
    margin-right: 30px;
    color: #203556;
    border-color: #d9d9d9;
    border-radius: 20px;
}

.ant-btn-default:hover,
.ant-btn-default:focus {
    color: #203556;
    border-color: #40a9ff;
    background-color: #fafafa;
}

.contact-social-icons {
    margin-top: 10px;
}

.contact-social-icons a {
    margin-right: 10px;
    color: #666;
}

.contact-social-icons a:hover {
    color: #1890ff;
}
.contact-number {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.contact-numbers {
    font-size: 24px !important;
    color: #203556 !important;
    font-weight: 700 !important;
}
.contact-number svg {
    transform: rotateY(180deg); /* This will flip the icon horizontally */
    margin-right: 10px; /* Adjust the margin as needed */
}

.contact-number span {
    color: #333;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .contact-form {
        padding: 15px;
    }
}

@media screen and (max-width: 576px) {
    .contact-form {
        padding: 10px;
    }

    .contact-form h2 {
        font-size: 20px;
    }
}

  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .banner-text h1 {
      font-size: 28px;
    }
  
    .title h2 {
      font-size: 20px;
    }
  
    .sub-title h4 {
      font-size: 15px;
    }
  
    .contact-para {
      font-size: 14px;
    }
  
    .contact-item span {
      font-size: 14px;
    }
  
    .contact-item p {
      font-size: 14px;
    }
    .contact-social-icons a i {
        font-size: 20px;
      }
  }