/* Default styles */
.carousel-custom .carousel-control-next,
.carousel-custom .carousel-control-prev {
  right: 0;
  left: auto;
  top: 80%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
  padding: 5px;
  font-weight: 800;
}
.social-icons {
  position: absolute;
  top: 300px;
  left: 100px;
  z-index: 5;
  color: white;
  display: flex;
  flex-direction: column; /* Change the flex direction to column */
  align-items: flex-start; 
  margin-top: 10px;
}

.social-icons > *:not(:last-child) {
  margin-bottom: 10px; /* Add margin bottom to all icons except the last one */
}

.carousel-custom .carousel-control-next-icon,
.carousel-custom .carousel-control-prev-icon {
  height: 45px;
  width: 45px !important;
  color: white;
  border: 2px solid white;
  border-radius: 50px !important;

}
.carousel-custom .carousel-control-next-icon:hover,
.carousel-custom .carousel-control-prev-icon:hover {
  color: white;
  border: 2px solid white;
  background-color: #e8174e;
}


.carousel-control-next,
.carousel-control-prev {
  margin-right: 60px; 
  width: auto;
  display: flex;
  align-items: center;
}

.carousel-control-prev {
  margin-right: 120px; /* Adjust the value as needed */
}

.carousel-caption {
  position: absolute;
  top: 30%;
  text-align: center;
}

.carousel-caption h1 {
  color: #fff;
  font-size: 58px;
  font-weight: 700;
  padding-bottom: 10px;
}
   
.carousel-caption p {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  display: block;
}

.carousel-caption button {
  margin-top: 0px;
}
.header-contents h1 {
  color: transparent; 
  background-image: linear-gradient(to right,#096a13, #49b915 ); 
  -webkit-background-clip: text; 
  background-clip: text; 
  font-size: 58px;
  font-weight: 700;
  text-align: left;
  padding-bottom: 10px;
}
.header-contents p {
  color: transparent; 
  background-image: linear-gradient(to right,#096a13, #49b915); 
  -webkit-background-clip: text; 
  background-clip: text; 
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}
/* Header.css */

.carousel-container {
  position: relative;
}
.down {
  position: absolute;
  top: -80px;
}
.scroll-down-content {
  position: absolute;
  top: 55%; /* Adjust as needed */
  right: 4%; /* Adjust as needed */
  transform: translateY(-50%);
  display: flex;
  flex-direction: column; /* Align content vertically */
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  z-index: 4;
}
/* Add this CSS to your existing stylesheet */
@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.8); /* Adjust the scale factor for zoom in */
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1.2); /* Adjust the scale factor for zoom in */
  }
  to {
    transform: scale(1);
  }
}

.zoom-in {
  animation: zoomIn 15s alternate infinite; /* Adjust animation duration as needed */
}

.zoom-out {
  animation: zoomOut 8s alternate infinite; /* Adjust animation duration as needed */
}


.scroll-down-content p {
  transform: rotate(90deg); /* Rotate the text vertically */
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .carousel-container::before {
    background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for smaller screens */
  }
  
  .social-icons {
    top: 10px;
    left: 10px;
  }

  .carousel-control-prev {
    margin-right: 30px; /* Adjusted margin for smaller screens */
  }

  .carousel-caption {
    top: 20%;
    left: 5%;
  }
  
  .carousel-caption h1 {
    font-size: 36px;
    padding-bottom: 20px;
  }
   
  .carousel-caption p {
    font-size: 16px;
  }
  .carousel-control-prev {
    margin-right: 100px; /* Adjust the value as needed */
  }
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .carousel-caption {
    position: absolute;
    top: 27%;
    left: 20%;
    text-align: center;
  }
  
  .carousel-caption h1 {
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    padding-bottom: 10px;
  }
     
  .carousel-caption p {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    display: block; /* Add this line */
  }
  .social-icons {
    position: absolute;
    top: 300px;
    left: 100px;
    z-index: 5;
    color: white;
    display: flex;
    flex-direction: column; /* Change the flex direction to column */
    align-items: flex-start; 
    margin-top: 10px;
  }
  .carousel-custom .carousel-control-next-icon,
.carousel-custom .carousel-control-prev-icon {
  height: 45px;
  width: 45px !important;
  color: white;
  border: 2px solid white;
}
}
@media only screen and (min-width: 450px) and (max-width: 599px) {
  .carousel-caption {
    position: absolute;
    top: 37%;
    left: 15%;
    text-align: center;
  }
  
  .carousel-caption h1 {
    color: #fff;
    font-size: 35px;
    font-weight: 700;
    text-align: left;
    padding-bottom: 10px;
  }
     
  .carousel-caption p {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    display: block; /* Add this line */
  }
  .social-icons {
    position: absolute;
    top: 150px;
    left: 60px;
    z-index: 5;
    color: white;
    display: flex;
    flex-direction: column; /* Change the flex direction to column */
    align-items: flex-start; 
    margin-top: 10px;
  }
  .carousel-custom .carousel-control-next-icon,
.carousel-custom .carousel-control-prev-icon {
  height: 35px;
  width: 35px !important;
  color: white;
  border: 2px solid white;
}
}
@media only screen and (min-width: 300px) and (max-width: 550px) {
 
  .carousel-custom .carousel-control-next-icon,
.carousel-custom .carousel-control-prev-icon {
  height: 35px;
  width: 35px !important;
  color: white;
  border: 2px solid white;
}
  .all-services {
    font-size: 12px !important;
    font-weight: 700;
    color: #203556;
  }
  .plus-icon {
    font-size: 10px;
    color: #3f516e;
  }

  
  .carousel-caption {
    position: absolute;
    top: 37%;
    left: 7%;
    text-align: center;
  }
  
  .carousel-caption h1 {
    color: #fff;
    font-size: 20px !important;
    font-weight: 700;
    text-align: left;
    padding-bottom: 10px;
  }
     
  .carousel-caption p {
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    text-align: left;
    display: block; /* Add this line */
  }
  .social-icons {
    position: absolute;
    top: 150px;
    left: 20px;
    z-index: 5;
    color: white;
    display: flex;
    flex-direction: column; /* Change the flex direction to column */
    align-items: flex-start; 
    margin-top: 10px;
  }
  .carousel-custom .carousel-control-next-icon,
.carousel-custom .carousel-control-prev-icon {
  height: 40px;
  width: 300x !important;
  color: white;
  border: 2px solid white;
}
.scroll-down-content {
  position: absolute;
  top: 55%; /* Adjust as needed */
  right: 0.01%; /* Adjust as needed */

}
.carousel-control-prev {
  margin-right: 100px; /* Adjust the value as needed */
}

}


