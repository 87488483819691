.navbar {
    width: 100%;
    background: linear-gradient(to right, #0a0608, #2a617e);
    z-index: 1000; /* Ensure the navbar appears above other content */
    /* position: fixed; */
    border-bottom: 1px solid white;
  }
  .navbar-container {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .navbar-links a.active {
    border-bottom: 2px solid white; /* Add your desired color here */
  }
  
  .navbar-logo img {
    width: 190px;
    padding-right: 15px; 
    border-right: 0.5px solid white; 
  }
  .active-link {
    text-decoration: underline;
    color: white;
  }
  .navbar-links a:hover {
    text-decoration: underline;
  }
  .navbar-links {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  
  .navbar-links a {
    color: white !important; 
    text-decoration: none;
    padding: 22px 5px; /* Adjust padding here */
    font-size: 15px;
    font-weight: 700;
  }

  .navbar-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  .navbar-toggle span {
    height: 2px;
    width: 25px;
    background-color: white;
    margin: 3px 0;
  }
  @media only screen and (max-width: 600px) {
 
    .logo p {
      font-size: 10px;
      font-weight: 700;
      padding:0px;
    }
    .navbar-logo span {
      font-size: 20px;
      font-weight: 700;
      color: #014bab;
    }
    
  }
  @media (max-width: 768px) {
    .navbar-links {
      padding: 10px 0px 20px 20px;
      display: none;
      flex-direction: column;
      position: absolute;
      top: 56px;
      right: 0;
      width: 100%;
      background: linear-gradient(to right, #0a0608, #2a617e);
      box-sizing: border-box;
      z-index: 4;
    }
    .navbar-links a {
      color: white;
      font-size: 12px;
      font-weight: 600;
      float: left;
    }
    .navbar-links.active {
      display: flex;
    }
    .navbar-toggle {
      display: flex;
    }
    .navbar-links a {
      color: white !important; 
      text-decoration: none;
      padding: 2px 8px; /* Adjust padding here */
      font-size: 15px;
      font-weight: 700;
      
    }
    .navbar {
      padding: 30px;
    }
    .navbar-logo img {
      width: 120px;
      padding-right: 15px; 
      border-right: 0.5px solid white; 
    }
    
  }

  