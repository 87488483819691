.equipmemts-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 450px;
    overflow: hidden;
    background-image: url('../assets/images/equipmentsBg.jpg');
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the background image */
}


.equipmemts-text {
    padding: 20px;
    color: white; /* Text color */
    max-width: 50%; /* Limit text width */
}

.equipmemts-text h1 {
    padding: 20px;
    color: rgba(255, 255, 255, 0.9);
    max-width: 50%;
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 30px;
    top: 40px;
}

.equipmemts-banner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity and color as needed */
}
.equipmemts-content h2 {
    padding: 20px 0px 20px 0px;
    font-size: 34px;
    color: #203556;
}
.equipments-contents img {
    width: 100%;
    height: 300px !important;
}