.title h2{
    padding-top: 40px;
    font-size: 75px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -0.04em;
    color: #203556; 
    padding-bottom: 20px;
  }
.title {
    margin-top: 20px;
    border-bottom: 1px solid #ececec; 
    padding-bottom: 17px; 
  }
  .home-content {
    color: #575e7e;
    text-align: justify;
    font-size: 18px;
  }
  .para-justify {
    text-align: justify;
    color: #575e7e;
    font-size: 16px;
  }
  .sub-title h4 {
    padding: 20px 0px 20px 0px;
    font-size: 32px;
    color: #203556; 
  }
  .custom-link {
    text-decoration: none;
  }
  .sub-title p {
    color: #575e7e;
    font-size: 18px;
    text-align: justify;
    
  }
  .service-name h6 {
    text-decoration: none;
  
  }
  
  .column-content img {
    margin-top: 15px;
    width: 100%;
    height: auto;
    border-radius: 10px !important; 
  }
  
  .column-content h3 {
    padding: 10px 0px 10px 0px;
    font-size: 28px;
    color: transparent; 
    background-image: linear-gradient(to right, #840808 ,#f77b7a); 
    -webkit-background-clip: text; 
    background-clip: text; 
  }
  
  .column-content p {
    font-size: 1em;
    text-align: justify;
    padding-bottom: 15px;
    color: #b6bcc5;
  }
  
  .Service-title h2 {
    padding-top: 40px;
    font-size: 70px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -0.04em;
    color: #203556; 
  }
  .plus-icon {
    color: #3f516e;
  }
  .service-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .plus-icon {
    margin-right: 8px;
  }
  .service-name h6{
    margin-top: 9px;
    font-size: 16px;
    font-weight: 500;
    color: #203556; 
  }
  .all-services {
    padding-bottom: 0px !important;
    padding-top: 10px;
    font-size: 19px;
    font-weight: 700;
    color: #203556; 
}
 /* Home.css or any other appropriate CSS file */
  .owl-carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
  }
  

 

  .items {
    padding: 10px;
  }
  .items h3 {
    padding: 10px 0px 10px 0px;
    font-size: 25px;
    color: #203556; 
  }
  .latest-content .date {
    text-align: left;
    color: #575e7e;
    font-size: 14px;
  }
  .items img {
    width: 100%;
    height: 280px;
  }
  .collapse-section {
    padding: 50px 0px 100px 0px;
  }
  .choosen-raycon h3 {
    padding: 40px 0px 10px 0px;
    font-size: 35px;
    color: transparent; 
    background-image: linear-gradient(to right, #840808 ,#f77b7a); 
    -webkit-background-clip: text; 
    background-clip: text; 
    font-weight: 700;
  }
  .OurEquipment h3 {
    padding: 10px 0px 10px 20px;
    font-size: 35px;
    color: transparent; 
    background-image: linear-gradient(to right, #840808 ,#f77b7a); 
    -webkit-background-clip: text; 
    background-clip: text; 
    font-weight: 700;
  }
  .OurEquipment-img {
    height: 300px;
  }
.redBackground {
    background-color: #07446e;
    padding: 10px;
    border-radius: 5px;
    color: white;
  }
  .equipments-content h5 {
    text-align: center;
    padding: 10px 0px 10px 0px;
    font-size: 25px;
    color: transparent; 
    background-image: linear-gradient(to right, #840808 ,#f77b7a); 
    -webkit-background-clip: text; 
    background-clip: text; 
    font-weight: 700;
  }
  .equipments-content p {
    text-align: center;
    padding: 0px 0px 10px 0px;
    font-size: 16px;
    color: #647389;
    font-weight: 700;

  }
  /* our Team styles */


 /* Home.css */
/* Home.css */
.ourTeamContainer {
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%;
  height: 400px;
  background-image: url(../assets/images/team.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
}

.content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: #fff; /* Text color */
  padding: 20px;
}

.content h1 {
  font-size: 48px; /* Decreased font size for better readability */
  font-weight: 700;
  margin-bottom: 20px; /* Spacing between heading and paragraph */
  display: inline; /* Display inline to keep text on the same line */
  color: transparent; /* Make text color transparent */
  -webkit-text-stroke: 1px #FFFBFB; /* Adding stroke to text */
}

.content p {
  font-size: 18px;
  line-height: 1.6; /* Improved readability with increased line height */
}

.content h1.animatedText {
  animation: slideLeft 10s linear infinite; /* Slower animation */
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Responsive Styling */
@media screen and (max-width: 768px) {
  .content h1 {
    font-size: 36px; /* Decrease font size for smaller screens */
  }

  .content p {
    font-size: 16px; /* Decrease font size for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .content h1 {
    font-size: 24px; /* Further decrease font size for smaller screens */
  }

  .content p {
    font-size: 14px; /* Further decrease font size for smaller screens */
  }
}


/* Home.css */

.background-images {
  background-image: url(../assets/images/outlineBg.jpg);
  background-size: cover; /* or 'contain' depending on your preference */
  background-position: center;
  height: 70vh; /* Adjust height as needed */
  overflow-x: hidden; /* Hide horizontal overflow */
}

.ourteam-content {
  position: relative; /* Required for absolute positioning of h1 */
  overflow: hidden; /* Hide overflowing content */
  width: 100%; /* Take full width */
}

.ourteam-content h1 {
  position: absolute; /* Position the h1 absolutely */
  animation: slideText 10s linear infinite; /* Animation properties */
  white-space: nowrap; /* Prevent wrapping of content */
  margin: 0; /* Remove default margin */
}

@keyframes slideText {
  0% {
    transform: translateX(100%); /* Start off-screen right */
  }
  100% {
    transform: translateX(-100%); /* End off-screen left */
  }
}


/* If you need to style individual items within the content, you can add additional CSS here */

  .statistic-title 
  {
    padding: 10px 0px 10px 0px;
    font-size: 22px;
    color: #585b60;
    font-weight: 600;
   text-align: center;
  }
  .statistic-main-title {
    padding: 10px 0px 10px 0px;
    font-size: 52px;
    color: #203556; 
    font-weight: 600;
  }
  .testimonials-content h3 {
    padding: 10px 0px 10px 0px;
    font-size: 35px;
    color: transparent; 
    background-image: linear-gradient(to right, #840808 ,#f77b7a); 

    -webkit-background-clip: text; 
    background-clip: text; 
    font-weight: 700;
  }
  .testimonials-content img {
    width: 70px !important;
  }
  .testimonials-bg {
    width: 100%;
    background-image: url(../assets/images/rewardsBg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .latest-title {
    padding-top: 40px;
  font-size: 90px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -0.04em;
  color: #203556; 
  padding-bottom: 40px;
  border-bottom: 2px solid #ececec
  }
  .latest-news h4 {
   padding-top: 20px;
  font-size: 30px;
  font-weight:500;
  color: #203556; 
  padding-bottom: 30px;
  }

  .latest-content h3 {
    text-align: center;
    padding-top: 10px;
    font-size: 18px;
    font-weight:500;
    color: #203556;
    padding-bottom: 10px;
  }
  .latest-content img {
    height: 200px;
  }
  .latest-content h2 {
    font-size: 18px;
    font-weight:700;
    color: #203556;
  }
  .latest-content p{
    font-size: 16px;
    font-weight:500;
    color: #6e7590;
  }
  .latest-content {
    padding: 20px;
  } 
  .connect-container {
    position: relative;
    height: 400px; /* Set the height of the container */
    background-size: cover; /* Ensure the background image covers the container */
    background-position: center; /* Center the background image */
    display: flex; /* Use flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
  }
  
  .contents {
    text-align: center; /* Center the text */
    color: white; /* Set the text color */
    z-index: 1; /* Ensure the content stays above the background */
  }
  
  .connect-title {
    color: #FFFFFF;
    font-size: 72px;
    font-weight: 600;
  }
  
  .connect-subtitle {
    font-size: 18px; /* Adjust the font size as needed */
  }
  .subtitle-container {
    display: inline-block;
  }
  .connect-container .button:hover {
    background-color: #FFFFFF !important;
    color:#6e7590 !important;
  }
  
  .subtitle-letter {
    display: inline-block;
    opacity: 0;
    animation: appear 0.5s ease-in-out forwards;
  }
  
  @keyframes appear {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .up-arrow {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-image: linear-gradient(to right, #840808 ,#f77b7a); 
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.up-arrow:hover {
    opacity: 0.8;
}

.up-arrow.hide {
    display: none;
}

/* CSS for the WhatsApp icon */
.whatsapp-icon {
    position: fixed;
    bottom: 100px;
    right: 20px;
    cursor: pointer;
}

.whatsapp-icon-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #be1e2d;
    transition: opacity 0.3s ease-in-out;
}

.whatsapp-icon-img:hover {
    opacity: 0.8;
}
  
  
  /* Media query for responsiveness */
  @media only screen and (max-width: 768px) {
  
    .title h2{
      padding-top: 10px;
      font-size: 50px !important;
      font-weight: 700;
      line-height: 55px;
      letter-spacing: -0.04em;
      padding-bottom: 5px;
    }
    .connect-title {
      font-size: 52px;
    }
  }
  
  @media only screen and (max-width: 576px) {
    .title h2{
      font-size: 30px !important;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: -0.04em;
    }
    
  }
  

  