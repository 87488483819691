.carrer-banner {
    position: relative;
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    width: 100%;
    height: 450px;
    overflow: hidden; 
    background-image: url('../assets/images/carrerBg.png'); 
    background-size: cover; 
    background-position: center; 
}

.carrer-text {
    padding: 20px;
    color: white;
    max-width: 50%;
}
.custom-panel {
    background-color: white ;
}
.carrer-text h1 {
    padding: 20px;
    color: rgba(255, 255, 255, 0.9);
    max-width: 50%;
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 50px;
    top: 60px;
}

.carrer-banner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
}
.testimonals-content h3{
    padding: 10px 0px 10px 0px;
    font-size: 28px;
    color: #475874;
}
.parkerTitle {
    padding: 6px 0px 0px 0px;
    color: transparent  !important; 
    background-image: linear-gradient(to right, #840808 ,#f77b7a) !important; 
    -webkit-background-clip: text  !important; 
    background-clip: text  !important; 
    text-align: left ;
}
.contact-form-container {
    background-color: #f8f8f8;
    padding: 50px 0;
}

.contact-form-container .container {
    max-width: 600px;
    margin: 0 auto;
}

.contact-form-container form {
    background-color: #fff;
    padding: 60px;
    border-radius: 50px;
    box-shadow: 0 0 10px #203556;
    border: 1px solid #b4baca;
}

.contact-form-container form .ant-form-item-label {
    font-weight: bold;
}



.contact-form-container form .ant-btn {
    margin-top: 10px;
}
