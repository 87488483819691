
  .brand-logo img {
    width: 150px !important;
  }
  
  .custom-nav-buttons {
    position: absolute;
    right: 120px; /* Adjust as needed */
  }
  .prev-btn {
    margin-right: 20px;
  }
  .prev-btn,
  .next-btn {
    background-color: #ccc;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .prev-btn:hover,
  .next-btn:hover {
    background-color: #6e7590;
  }
  