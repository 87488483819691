/* Assuming your header has a class name of "header" */
.header {
    position: relative;
}

.about-banner {
    position: relative;
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    width: 100%;
    height: 450px; 
    overflow: hidden; 
    background-image: url('../assets/images/aboutBanner.jpg'); /* Set the background image */
    background-size: cover; 
    background-position: center; 
}

.banner-text {
    padding: 20px;
    color: white; 
    max-width: 50%; 
}

.banner-text h1 {
    padding: 20px;
    color: rgba(255, 255, 255, 0.9); 
    max-width: 50%; 
    position: relative; 
    z-index: 1; 
    font-weight: 700;
    font-size: 50px;
    top: 60px;
}

.about-banner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
}

.about-content img {
    height: auto;
}

.about-content-title {
  font-size: 32px;
  color: transparent; 
  background-image: linear-gradient(to right, #840808 ,#f77b7a); 
  -webkit-background-clip: text; 
  background-clip: text; 
}
.w3-animate-right {
    animation-duration: 1s; 
  }
  .w3-animate-left {
    animation-duration: 1s; 
  }
  .w3-animate-bottom {
    animation-duration: 1s; 
  }
  .w3-animate-top {
    animation-duration: 1s; 
  }
  .parkerTitle {
    padding: 0px !important;
    font-weight: 600 !important;
  }
@keyframes move-top {
    0%, 100% {
      transform: translateY(-10px); 
    }
    50% {
      transform: translateY(20px); 
    }
  }
  
  @keyframes move-bottom {
    0%, 100% {
      transform: translateY(20px); /* Move the image 50px downwards initially and finally */
    }
    50% {
      transform: translateY(-10px); /* Move the image 50px upwards at the midpoint */
    }
  }
  .about-content-para {
    color: #4a5b75;
  }
  .show-image-container {
    overflow: hidden;
    position: relative;
  }
  .card-slider {
    padding-top: 70px;
  }
  .zoom-image {
    transition: transform 0.3s ease;
  }
  
  .zoom-image:hover {
    transform: scale(1.1);
  }
  
  
/* Responsive styles */
@media screen and (max-width: 768px) {
    .banner-text h1 {
        font-size: 40px; /* Adjust font size for smaller screens */
    }
}

@media screen and (max-width: 576px) {
    .banner-text h1 {
        font-size: 30px; /* Adjust font size for even smaller screens */
    }
    .banner-text {
        max-width: 80%; /* Adjust text width for smaller screens */
    }
    .about-banner {
        height: 300px; /* Adjust banner height for smaller screens */
    }
}
